



























































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Resolve } from 'vue-di';
import { faPrescription, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { mapKeys } from 'lodash';
import Header from '~/components/Header/Header.vue';
import FloatingFooter from '~/components/Footer/FloatingFooter.vue';
import Footer from '~/components/Footer/Footer.vue';
import StaticFooter from '~/components/Footer/StaticFooter.vue';
import ScrollingFooter from '~/components/Footer/ScrollingFooter.vue';
import ConfigurationDrawer from '~/components/configuration/ConfigurationDrawer.vue';
import MedPrescribingInfo from '~/components/MedPrescribingInfo.vue';
import theme from '~/settings/theme';
import SessionModule from '~/store/session';
import LayoutModule from '~/store/layout';
import IriModule from '~/store/iri';
import IdleTimeout from '~/components/session/IdleTimeout.vue';

@Component<DefaultLayout>({
  components: {
    Header,
    FloatingFooter,
    Footer,
    StaticFooter,
    ScrollingFooter,
    ConfigurationDrawer,
    MedPrescribingInfo,
    IdleTimeout,
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((this as any).$meta) (this as any).$meta().refresh();
  },
  icons: {
    faPrescription,
    faChevronUp,
    faChevronDown,
  },
})
export default class DefaultLayout extends Vue {
  @Resolve
  public layout!: LayoutModule;

  @Resolve
  public iri!: IriModule;

  @Resolve
  public session!: SessionModule;
  public theme = theme;

  // Allows user to close the configuration drawer by hitting escape
  created() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.drawer = false;
      }
    });
  }

  async onActive() {
    // eslint-disable-next-line no-console
    console.log('vue user is active');
  }

  async onIdle() {
    // eslint-disable-next-line no-console
    console.log('vue on session timeout');
  }

  @Watch('this.$route.query', { immediate: true })
  setProgram() {
    const program = this.$route.query.program as 'test';
    if (program === 'test') {
      this.layout.setProgram(program);
      this.layout.setButtonsDisabled(false);
    }
  }

  @Watch('layout.configuration.programName', { deep: true })
  public watchProgramName(programName: string) {
    this.layout.replaceProgramName(programName, this.layout.previousProgramName);
  }

  @Watch('layout.configuration.colors', { deep: true, immediate: true })
  public setInitialColors(configuration: typeof LayoutModule.prototype.configuration) {
    Object.entries(this.layout.configuration.colors).forEach(([name, color]) => {
      document.documentElement.style.setProperty(`--color-${name}`, color.toUpperCase());
      this.$vuetify.theme.themes.light[name] = color.toUpperCase();
    });
  }

  public get drawer() {
    return this.layout.drawer;
  }
  public set drawer(v) {
    this.layout.setDrawer(v);
  }
  public get mini() {
    return this.layout.mini;
  }
  public set mini(v) {
    this.layout.setMini(v);
  }
  public get iriOpen() {
    return this.iri.open;
  }
  public set iriOpen(v) {
    this.iri.toggleIriOpen(v);
  }

  public get menu(): object {
    return this.$settings.mainMenu;
  }
  public back() {
    this.$router.back();
  }

  public get brkPoints() {
    return {
      'brk-xs': this.$vuetify.breakpoint.xs,
      'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
      'brk-sm': this.$vuetify.breakpoint.sm,
      'brk-sm-only': this.$vuetify.breakpoint.smOnly,
      'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
      'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
      'brk-md': this.$vuetify.breakpoint.md,
      'brk-md-only': this.$vuetify.breakpoint.mdOnly,
      'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
      'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
      'brk-lg': this.$vuetify.breakpoint.lg,
      'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
      'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
      'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
      'brk-xl': this.$vuetify.breakpoint.xl,
      'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
    };
  }
}
