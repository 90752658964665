import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d21d2270 = () => interopDefault(import('..\\pages\\access-your-account.vue' /* webpackChunkName: "pages_access-your-account" */))
const _4b44c7f6 = () => interopDefault(import('..\\pages\\access-your-card.vue' /* webpackChunkName: "pages_access-your-card" */))
const _356bb3f4 = () => interopDefault(import('..\\pages\\card-print.vue' /* webpackChunkName: "pages_card-print" */))
const _16b1d3fd = () => interopDefault(import('..\\pages\\program-requirements.vue' /* webpackChunkName: "pages_program-requirements" */))
const _171bb61a = () => interopDefault(import('..\\pages\\rebate-form.vue' /* webpackChunkName: "pages_rebate-form" */))
const _55e5a988 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _0a165115 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _0a246896 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _0a409798 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _0a4eaf19 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _3efc8c56 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _3f94e33e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-your-account",
    component: _d21d2270,
    name: "access-your-account"
  }, {
    path: "/access-your-card",
    component: _4b44c7f6,
    name: "access-your-card"
  }, {
    path: "/card-print",
    component: _356bb3f4,
    name: "card-print"
  }, {
    path: "/program-requirements",
    component: _16b1d3fd,
    name: "program-requirements"
  }, {
    path: "/rebate-form",
    component: _171bb61a,
    name: "rebate-form"
  }, {
    path: "/unlock",
    component: _55e5a988,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _0a165115,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _0a246896,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _0a409798,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _0a4eaf19,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _3efc8c56,
    name: "errors-500"
  }, {
    path: "/",
    component: _3f94e33e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
