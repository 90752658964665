

































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';
import MedPrescribingInfo from '~/components/MedPrescribingInfo.vue';

@Component<Footer>({
    components: {
        MedPrescribingInfo,
    },
})
export default class Footer extends Vue {
    @Resolve
    public layout!: LayoutModule;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    mounted() {
        this.layout.replaceProgramName(this.layout.configuration.programName, this.layout.previousProgramName);
    }

    get phoneNumber() {
        return this.layout.program === 'pah' ? '1-866-228-3546' : '866-708-8987';
    }

    get showMedPrescLink() {
        const rebate = this.$route.path.includes('rebate');
        const card = this.$route.path.includes('card');
        const requirements = this.$route.path.includes('requirements');
        if (rebate || card || requirements) {
            return true;
        } else {
            return false;
        }
    }

    @Watch('layout.configuration.programName', { deep: true })
    public watchProgramName(programName: string) {
        this.layout.replaceProgramName(programName, this.layout.previousProgramName);
    }

    public get footerInfoLinkLocation() {
        return this.layout.configuration.footerInfoLinkLocation;
    }

    public get isTopOrBottomBar() {
        return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
    }

    public get isLeftOrRightBar() {
        return ['left', 'right'].includes(this.footerInfoLinkLocation);
    }
}
